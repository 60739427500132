module.exports = [{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-manifest@4.15.1_gatsby@4.14.0_graphql@15.5.0/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"cfcd6d68a229804df66de4ebca616efe"},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-use-query-params@1.0.1_gatsby@4.14.0_react-dom@17.0.2_react@17.0.2_use-query-params@1.2.3/node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/.pnpm/@sentry+gatsby@7.37.2_gatsby@4.14.0_react@17.0.2/node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
