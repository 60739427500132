import * as React from 'react';
import {
  Auth0Client,
  GetIdTokenClaimsOptions,
  GetTokenSilentlyOptions,
  IdToken,
  LogoutOptions,
  RedirectLoginOptions,
  RedirectLoginResult,
  User
} from '@auth0/auth0-spa-js';

const genAuth0Client = () => {
  if (typeof document !== `undefined`) {
    return new Auth0Client({
      domain: process.env.GATSBY_AUTH0_DOMAIN,
      client_id: process.env.GATSBY_AUTH0_CLIENTID,
      redirect_uri: process.env.GATSBY_AUTH0_REDIRECTURI,
      audience: process.env.GATSBY_AUTH0_AUDIENCE,
      cacheLocation: 'localstorage',
      useCookiesForTransactions: true,
      useRefreshTokens: true,
      legacySameSiteCookie: true,
      scope: 'openid profile email offline_access'
    });
  }
};

const auth0 = genAuth0Client();

type IAuthContext = {
  // Auth state
  error?: Error;
  isAuthenticated: boolean;
  isLoading: boolean;
  user?: User;
  authToken?: string;

  // Auth methods
  getAccessTokenSilently: (options?: GetTokenSilentlyOptions) => Promise<string>;
  getIdTokenClaims: (options?: GetIdTokenClaimsOptions) => Promise<undefined | IdToken>;
  handleRedirectCallback: (url?: string) => Promise<RedirectLoginResult>;
  loginWithRedirect: (options?: RedirectLoginOptions) => Promise<void>;
  logout: (options?: LogoutOptions) => void | Promise<void>;
};

export const AuthContext = React.createContext<IAuthContext>({
  isAuthenticated: false,
  isLoading: true,
  user: {},

  getAccessTokenSilently: () => Promise.reject(),
  getIdTokenClaims: () => Promise.reject(),
  handleRedirectCallback: () => Promise.reject(),
  loginWithRedirect: () => Promise.reject(),
  logout: () => Promise.reject()
});

export const AuthProvider = ({children}) => {
  const [error, setError] = React.useState<Error | undefined>();
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [authToken, setAuthToken] = React.useState<string | undefined>();
  const [user, setUser] = React.useState({});

  const getAccessTokenSilently = async (options?: GetTokenSilentlyOptions) => {
    let token: string;
    try {
      token = await auth0.getTokenSilently(options);
      setAuthToken(token);
    } catch (e) {
      console.error('error getting token silently', e);

      return;
    }
    const auth0user = await auth0.getUser();

    setUser(auth0user);
    return token;
  };

  const getIdTokenClaims = React.useCallback((opts) => auth0.getIdTokenClaims(opts), [auth0]);

  const handleRedirectCallback = async (url?: string) => {
    try {
      setIsAuthenticated(true);
      const callback = await auth0.handleRedirectCallback(url);

      await getAccessTokenSilently();

      return callback;
    } catch (e) {
      console.log(e);

      if (e?.message === 'Invalid state') {
        await loginWithRedirect();
      }

      setError(e?.message);
      setIsAuthenticated(false);
    }
  };

  const loginWithRedirect = (options?: RedirectLoginOptions) => {
    if (typeof window === 'undefined') return; // for server side compilation
    return auth0.loginWithRedirect(options);
  };

  const logout = async (options?: LogoutOptions) => {
    return auth0.logout(options);
  };

  React.useEffect(() => {
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        if (token) {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
      } catch (e) {
        if (e.error !== 'login_required') {
          setError(e.message);
          throw e;
        }
        setIsAuthenticated(false);
      }
      setIsLoading(false);
    })();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        error,
        isAuthenticated,
        isLoading,
        user,
        authToken,
        getAccessTokenSilently,
        getIdTokenClaims,
        handleRedirectCallback,
        loginWithRedirect,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
}
