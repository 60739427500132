import * as React from 'react';
import {AuthProvider} from '../Auth/AuthContext';
import {withLDProvider} from 'launchdarkly-react-client-sdk';

const RootLayout = ({children}) => {
  return <AuthProvider>{children}</AuthProvider>;
};

const context = {
  kind: 'user',
  key: 'zeus-portal'
};

export default withLDProvider({
  clientSideID: process.env.GATSBY_LAUNCHDARKLY_CLIENT_ID,
  context: context
})(RootLayout);
